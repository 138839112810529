import React from "react";
import classNames from "classnames/bind";
import styles from "./TutorialMain.module.scss";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

const cx = classNames.bind(styles);

const PostLink = ({ url, title }) => {
  return (
    <li className={cx("postLI")}>
      <Link to={url} className={cx("postLink")}>
        {title}
      </Link>
    </li>
  );
};

const TutorialMain = ({ tutorial, posts }) => {
  const { frontmatter, html } = tutorial;

  return (
    <section className={cx("tutorialMain")}>
      <Row>
        <Col className={cx("titleArea")}>
          <h1 className={cx("tutorialTitle")}>{frontmatter.title}</h1>
          <img
            src={frontmatter.image.childImageSharp.fluid.src}
            alt={frontmatter.title}
            className={cx("tutorialImage")}
          />
        </Col>
      </Row>

      <Row className="py-5">
        <Col lg={8} md={8}>
          <article
            className="markdown"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className={cx("titleMeta")}>
            <span>{frontmatter.date}</span>
          </div>
        </Col>
        <Col lg={4} md={4}>
          <div className={posts.length <= 15 ? "sticky-item" : ""}>
            <ul className={cx("postsUL")}>
              {posts.map(({ node }) => {
                return (
                  <PostLink
                    key={node.fields.slug}
                    url={node.fields.slug}
                    title={node.frontmatter.title}
                  />
                );
              })}
            </ul>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default TutorialMain;
