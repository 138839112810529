import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import TutorialMain from "../components/TemplateTutorial/TutorialMain";

export const pageQuery = graphql`
  query TutorialQuery($slug: String!, $tutorial: String!) {
    tutorial: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tutorial
        date(formatString: "YYYY년 MM월 DD일")
        author
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
            }
          }
        }
      }
      excerpt
      html
      fields {
        slug
      }
    }

    postsInTutorial: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/data/([a-z])+/posts//" }
        frontmatter: { tutorial: { eq: $tutorial } }
      }
      sort: {
        fields: [
          frontmatter___tutorial
          frontmatter___order
          frontmatter___date
        ]
        order: ASC
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 160)
          frontmatter {
            title
            tutorial
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TutorialTemplate = ({ location, data }) => {
  const { tutorial, postsInTutorial } = data;

  return (
    <Layout location={location}>
      <SEO title={tutorial.frontmatter.title} description={tutorial.excerpt} />

      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <Row className="py-5">
            <Col xl={2} lg={1} className="d-none d-lg-block" />

            <Col xl={8} lg={10}>
              <TutorialMain tutorial={tutorial} posts={postsInTutorial.edges} />
              <Row>
                <Col>🕶</Col>
              </Row>
            </Col>

            <Col xl={2} lg={1} className="d-none d-lg-block" />
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default TutorialTemplate;
